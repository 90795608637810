<template>
  <div class="journal_detail_wrap">
    <h1 class="h_none">蓝灯鱼AI开放平台</h1>
    <h2 class="h_none">蓝灯鱼,lanternfish,蓝灯鱼商标,蓝灯鱼翻译,蓝灯鱼专利,AI,人工智能</h2>
    <div style="height: 90px"></div>

    <!--    商标评审-->
    <div id="pdf1" class="journal_detail" v-if="$route.query.flag == 0">
      <div class="btnPdf no-print"  @click="pdf" ></div>
      <div class="journal_title">
        <img src="../../assets/images/judgementDocumentDetail/left.png" alt="">
        <p v-html="detailData.title"></p>
        <img src="../../assets/images/judgementDocumentDetail/right.png" alt="">
      </div>

      <div class="journal_line"></div>

      <ul class="journal_content">
        <li>
          <span>审判时间: <i v-html="detailData['judgeDate']"></i></span>
          <span>文书号: <i v-html="detailData['docNo']"></i></span>
        </li>
      </ul>

      <div v-if="detailData['applicant']" class="journal_item">
        <div class="title">当事人</div>
        <p v-if="detailData['applicant']" :style="{textIndent: '2em'}" >申请人：<i v-html="detailData['applicant']"></i></p>
        <p v-if="detailData['applicantAgency']" :style="{textIndent: '2em'}" >申请人代理机构：<i v-html="detailData['applicantAgency']"></i></p>
        <p v-if="detailData['respondent']" :style="{textIndent: '2em'}" >被申请人：<i v-html="detailData['respondent']"></i></p>
        <p v-if="detailData['respondentAgency']" :style="{textIndent: '2em'}" >被申请人代理机构：<i v-html="detailData['respondentAgency']"></i></p>
      </div>

      <div v-if="detailData.trialProcess" class="journal_item">
        <div class="title">审理经过</div>
        <p  :style="{textIndent: '2em'}"  v-html="detailData.trialProcess"></p>
      </div>

      <div v-if="detailData.applyReason" class="journal_item">
        <div class="title">申请理由</div>
        <p  :style="{textIndent: '2em'}"  v-html="detailData.applyReason"></p>
      </div>

      <div v-if="detailData.judgeResult" class="journal_item">
        <div class="title">决定结果</div>
        <p :style="{textIndent: '2em'}"  v-html="detailData.judgeResult"></p>
      </div>

      <div v-if="detailData['collegialPanel'] && detailData['collegialPanel'].length" class="journal_item">
        <div class="title">合议组成员</div>
        <p :style="{textIndent: '2em'}" :key="index" v-for="(item,index) in detailData['collegialPanel']" v-html="item"></p>
      </div>

    </div>
    <!--    裁判文书-->
    <div id="pdf2" class="journal_detail" v-if="$route.query.flag == 1">
      <div class="journal_title">
        <img src="../../assets/images/judgementDocumentDetail/left.png" alt="">
        <p v-html="detailData.title"></p>
        <img src="../../assets/images/judgementDocumentDetail/right.png" alt="">
      </div>

      <div class="journal_line"></div>

      <ul class="journal_content">
        <li>
          <span>案件性质：<i v-html="detailData['caseNature']"></i></span>
          <span v-if="detailData['caseCause'] && detailData['caseCause'].length">案由：<i v-html="detailData['caseCause'].join('、')"></i></span>
          <span v-else>案由：</span>
          <span>裁决结果：<i v-html="detailData['judgeResult']"></i></span>
        </li>
        <li>
          <span>案件地区：<i v-html="detailData['caseArea']"></i></span>
          <span>案号：<i v-html="detailData['caseNo']"></i></span>
          <span>案例等级：<i v-html="detailData['caseDocLevel']"></i></span>
        </li>
        <li>
          <span>判决标题：<i v-html="detailData['判决标题']"></i></span>
          <span>立案年度：<i v-html="detailData['caseYear']"></i></span>
          <span></span>
        </li>
        <li>
          <span>判决法院：<i v-html="detailData['判决法院名称']"></i></span>
          <span>判决日期：<i v-html="detailData.judgeDate"></i></span>
          <span></span>
        </li>
      </ul>

      <div v-if="detailData['上诉人与上诉人信息'] && detailData['上诉人与上诉人信息'].length" class="journal_item">
        <div class="title">上诉人与被上诉人信息</div>
        <p :style="{textIndent: '2em', wordBreak:'break-all'}" :key="index" v-for="(item,index) in detailData['上诉人与上诉人信息']" v-html="item"></p>
      </div>

      <div v-if="detailData['审理经过'] && detailData['审理经过'].length" class="journal_item">
        <div class="title">审理经过</div>
        <p :style="{textIndent: '2em', wordBreak:'break-all'}" :key="index" v-for="(item,index) in detailData['审理经过']" v-html="item">
        </p>
      </div>

      <div v-if="detailData['审理查明'] && detailData['审理查明'].length" class="journal_item">
        <div class="title">审理查明</div>
        <p :style="{textIndent: '2em', wordBreak:'break-all'}" :key="index" v-for="(item,index) in detailData['审理查明']" v-html="item">

        </p>
      </div>

      <div v-if="detailData['法院认为'] && detailData['法院认为'].length" class="journal_item">
        <div class="title">本院认为</div>
        <p :style="{textIndent: '2em', wordBreak:'break-all'}" :key="index" v-for="(item,index) in detailData['法院认为']" v-html="item">

        </p>
      </div>

      <div v-if="detailData['裁判结果'] && detailData['裁判结果'].length" class="journal_item">
        <div class="title">裁判结果</div>
        <p :style="{textIndent: '2em', wordBreak:'break-all'}" :key="index" v-for="(item,index) in detailData['裁判结果']" v-html="item">

        </p>
      </div>
    </div>
    <!--    开庭公告-->
    <div id="pdf3" class="journal_detail" v-if="$route.query.flag == 2">
      <div class="journal_title">
        <img src="../../assets/images/judgementDocumentDetail/left.png" alt="">
        <p v-html="detailData.title"></p>
        <img src="../../assets/images/judgementDocumentDetail/right.png" alt="">
      </div>

      <div class="journal_line"></div>

      <ul class="journal_content">
        <li>
          <span>案件性质：<i v-html="detailData['caseNature']"></i></span>
          <span>案由：<i v-html="detailData['caseCause']"></i></span>
          <span>受理法院：<i v-html="detailData['court']"></i></span>
        </li>
        <li>
          <span>案件地区：<i v-html="detailData['province']"></i>&nbsp;&nbsp;<i v-html="detailData['city']"></i></span>
          <span>案号：<i v-html="detailData['referenceNum']"></i></span>
          <span>庭审时间：<i v-html="detailData['courtTime']"></i></span>
        </li>
        <li>
          <span>法庭：<i v-html="detailData['court']"></i></span>
          <span>原告：<i v-html="detailData['plaintiff']"></i></span>
          <span style="white-space: break-spaces">被告：<i v-html="detailData['defendant']"></i></span>
        </li>
      </ul>

      <div v-if="detailData.courtLoc" class="journal_item">
        <div class="title">庭审地址</div>
        <p :style="{textIndent: '2em'}" v-html="detailData.courtLoc"></p>
      </div>

      <div v-if="detailData.plaintiff" class="journal_item">
        <div class="title">原告</div>
        <p :style="{textIndent: '2em'}"  v-html="detailData.plaintiff"></p>
      </div>

      <div v-if="detailData.defendant" class="journal_item">
        <div class="title">被告</div>
        <p :style="{textIndent: '2em'}"  v-html="detailData.defendant"></p>
      </div>

      <div v-if="detailData.rawData" class="journal_item">
        <div class="title">原文</div>
        <p :style="{textIndent: '2em'}"  v-html="detailData.rawData"></p>
      </div>

    </div>
  </div>
</template>
<script>
  import { mapState, mapMutations } from "vuex";
  import { JOURNAL, JOURNALDETAIL } from "@/request/common";
  export default {
    name: 'journalDetail',
    metaInfo: {
      title: '商标查询|专利翻译|知识产权管理系统-蓝灯鱼智能检索平台', // set a title
      meta: [{                 // set meta
        name: 'keyWords',
        content: '商标查询，商标近似查询，商标图形查询，专利翻译，专利查询，专利检索'
      }, {                 // set meta
        name: 'description',
        content: '蓝灯鱼是将人工智能技术运用到知识产权行业的平台。通过AI技术智能化商标查询,专利翻译,专利检索；提供在线使用和API接入等服务'
      }],
    },
    data () {
      return {
        detailData: ''
      }
    },
    mounted () {
      this.init();
    },
    methods: {
      //PDF导出功能
      pdf(){
        //pdf 打印置顶
        // document.body.scrollTop = document.documentElement.scrollTop = 0
        this.getPdf(this.$route.query.flag == 0 ? "pdf1" : this.$route.query.flag == 1 ? "pdf2" : "pdf3", this.detailData['title']);
      },
      init () {
        if(this.$route.query.flag == 0){
          this.$axios.get('/trademark/iphouseApi/ReviewCaseDetail?cid=' + this.$route.query.id + '&keyword=' + this.$route.query.keyword).then(({data}) => {
            if(data){
              this.detailData = data
            }else {
              // this.trademarkTable = []
              this.$message.error('查询失败');
            }
          })
            .catch((error) => {
              //   this.$nuxt.$loading.finish()
              this.$message.error('查询失败');
              this.trademarkTable = []
              console.log(error);
            })
        }else if(this.$route.query.flag == 1){
          this.$axios.get('/trademark/iphouseApi/judgementDocumentDetail?cid=' + this.$route.query.id + '&keyword=' + this.$route.query.keyword).then(({data}) => {
            if(data){
              this.detailData = data
            }else {
              // this.trademarkTable = []
              this.$message.error('查询失败');
            }
          })
            .catch((error) => {
              //   this.$nuxt.$loading.finish()
              this.$message.error('查询失败');
              this.trademarkTable = []
              console.log(error);
            })
        }else if(this.$route.query.flag == 2){
          this.$axios.get('/trademark/iphouseApi/courtNoticeDetail?cid=' + this.$route.query.id + '&keyword=' + this.$route.query.keyword).then(({data}) => {
            if(data){
              this.detailData = data
            }else {
              // this.trademarkTable = []
              this.$message.error('查询失败');
            }
          })
            .catch((error) => {
              //   this.$nuxt.$loading.finish()
              this.$message.error('查询失败');
              this.trademarkTable = []
              console.log(error);
            })
        }

      }
    },
    computed: {
      ...mapState(["me"])
    },
  }
</script>
<style lang="less" scoped>
  .journal_detail_wrap {
    background: #e2e8f6;
    height: 100%;
    width: 100%;
    /deep/.specialContent{
      color: red;
    }

    .journal_detail{
      .btnPdf{
        width: 42px;
        height: 49px;
        position: fixed;
        top: 120px;
        right: 15%;
        cursor: pointer;
        background: url("../../assets/images/query/load.png") no-repeat;
      }
      width: 1200px;
      background: white;
      padding: 0 44px 100px;
      margin: 0 auto;
      box-sizing: border-box;
      min-height: 1000px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .journal_title{
        display: flex;
        align-items: center;
        font-size: 24px;
        color: #4a5990;
        margin: 43px auto 0;
        img{
          width: 55px;
        }
      }
      .journal_line{
        width: 100%;
        height: 2px;
        margin-top: 40px;
        margin-bottom: 25px;
        background: linear-gradient(
          to right,
          transparent 0%,
          transparent 50%,
          #ccc 50%,
          #ccc 100%
        );
        background-size: 35px 1px;
        background-repeat: repeat-x;
      }
      .journal_content{
        width: 1115px;
        font-size: 14px;
        color: #404040;
        padding: 0 60px;
        box-sizing: border-box;
        justify-content: center;
        margin-bottom: 52px;
        li{
          line-height: 32px;
          display: flex;
          span{
            display: block;
            width: 345px;
            overflow: hidden;//溢出隐藏
            white-space: nowrap;//禁止换行
            text-overflow: ellipsis;//...
          }
        }
      }
      .journal_item{
        width: 100%;
        padding-left: 16px;
        box-sizing: border-box;
        .title{
          height: 36px;
          width: 474px;
          background: url("../../assets/images/judgementDocumentDetail/journal_item_bg.png") no-repeat ;
          background-size: contain;
          font-size: 16px;
          color: white;
          font-weight: 600;
          padding-left: 29px;
          padding-top: 8px;
          box-sizing: border-box;
          margin-bottom: 20px;
          margin-top: 20px;
        }
        p{
          padding: 15px 0px 15px 30px;
          line-height: 1.5;
        }
      }
    }
  }
</style>
<style lang="less">
  .journal_detail_wrap {
    .journal_detail_con {
      strong {
        font-weight: 900;
      }
    }
  }
</style>
